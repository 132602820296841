import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
// import styled from 'styled-components';
import { globalHistory } from '@reach/router';

import * as S from './Sidebar.styles';
import { menuData } from '../../helpers/menuData';

export const Sidebar = () => {
  const path = globalHistory.location.pathname;

  return (
    <S.Container>
      <S.SubContainer>
        <StaticImage
          src="../../images/logonegro.png"
          alt="Logo"
          width={150}
          placeholder="blurred"
        />

        <S.Menu>
          {menuData.map((item, i) => {
            return (
              <li key={i} className={`${path === item.url ? '__active' : ''}`}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            );
          })}
        </S.Menu>

        <div>
          <StaticImage
            src="../../images/logo-mcap.png"
            alt="Logo"
            width={150}
            placeholder="blurred"
          />
          {/* <TextoLeyenda>
            Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y
            las Artes. FONDART Regional Magallanes y la Antártica Chilena 2020.
          </TextoLeyenda> */}
        </div>
      </S.SubContainer>
    </S.Container>
  );
};

// const TextoLeyenda = styled.p`
//   line-height: 1.5;
//   font-family: sans-serif;
//   background: none;
//   font-size: 11px;
//   text-align: center;
//   padding: 5px 5px 5px 12px;
// `;
