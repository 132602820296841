export const menuData = [
  {
    label: 'Expedición',
    url: '/expedicion',
  },
  {
    label: 'La Cámara',
    url: '/lacamara',
  },
  {
    label: 'Fotografías',
    url: '/fotografias',
  },
  {
    label: 'Documental',
    url: '/documental',
  },
  {
    label: 'Exposición',
    url: '/exposicion',
  },
  /* {
    label: "Libro",
    url: "/libro",
  }, */
  {
    label: 'Equipo',
    url: '/equipo',
  },
  {
    label: 'Agradecimientos',
    url: '/agradecimientos',
  },
];
