import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { globalHistory } from "@reach/router";

import * as S from "./Navbar.styles";
import { menuData } from "../../helpers/menuData";

export const Navbar = () => {
  const path = globalHistory.location.pathname;

  const [isOpenMenu, setisOpenMenu] = useState(false);

  const disableBodyScroll = () => {
    document.body.style.overflow = isOpenMenu ? "hidden" : "unset";
  };

  useEffect(() => {
    disableBodyScroll();
  }, []);

  return (
    <S.Container>
      <S.Navbar>
        <StaticImage
          src="../../images/logonegro.png"
          alt="Logo"
          width={80}
          // height={200}
          placeholder="blurred"
        />

        <S.MenuHamb onClick={() => setisOpenMenu((prev) => !prev)}>
          <div className="__line"></div>
          <div className="__line"></div>
          <div className="__line"></div>
        </S.MenuHamb>
      </S.Navbar>

      <S.MenuBody active={isOpenMenu}>
        {menuData.map((item, i) => {
          return (
            <li key={i} className={`${path === item.url ? "__active" : ""}`}>
              <Link to={item.url}>{item.label}
              </Link>
            </li>
          );
        })}
      </S.MenuBody>
    </S.Container>
  );
};
