import React from "react";
import GlobalStyle from "../styles/globalStyles";

import { Helmet } from "react-helmet"

import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";

import * as S from "./Layout.styles";

export const Layout = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title} | La Cámara de Gusinde</title>
      </Helmet>
      
      <GlobalStyle />


      <S.Container>
        <Navbar />
        <Sidebar />
        <S.Children>{children}</S.Children>
      </S.Container>
    </>
  );
};
