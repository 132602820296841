import styled from "styled-components";

export const Container = styled.div`
  & > p {
    text-align: justify;
  }

  & > .gatsby-image-wrapper {
    margin: 20px 0px;
  }
`;
