import styled from "styled-components";

export const Container = styled.div`
`;

export const Navbar = styled.nav`
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MenuHamb = styled.div`
  cursor: pointer;
  width: 35px;

  & > .__line {
    background-color: black;
    height: 3px;
    margin: 8px 0;
    width: 100%;
  }
`;

export const MenuBody = styled.ul`
  display: grid;

  @media (min-width: 768px) {
    display: none;
  }

  position: fixed;
  top: 87px;
  width: 100%;
  background-color: white;
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: opacity 0.5s;

  list-style: none;
  padding: 30px 0;
  margin: 0;
  place-items: center;
  height: calc(100vh - 147px);

  & a {
    color: inherit;
    text-decoration: none;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
  }

  & > li {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    

    &:hover {
      opacity: 0.7;
    }

    &.__active {
      font-weight: 800;
      opacity: 0.3;
    }
  }

  z-index: 1000;
`;
