import styled from 'styled-components';

export const SubContainer = styled.div`
  min-height: 670px;
  height: calc(100vh - 40px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;

  @media (max-width: 768px) {
    display: none;
  }

  background-color: white;
  height: calc(100vh - 40px);
  overflow: auto;
  padding: 20px 0px;
  text-align: center;
  width: 250px;
  min-width: 250px;
`;

export const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  & a {
    color: inherit;
    text-decoration: none;
  }

  & > li {
    padding: 10px 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    text-align: center;

    &:hover {
      opacity: 0.7;
    }

    &.__active {
      font-weight: 800;
      opacity: 0.3;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
`;
