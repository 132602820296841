import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from '../../Layout';
import { Container } from '../../styles/pages/Expedicion.styles';

const Fotografias = () => {
  return (
    <Layout title="Fotografías">
      <Container>
        {/* <StaticImage
          src="../../images/fotografias/1.jpg"
          alt="imagen"
          placeholder="blurred"
        /> */}

        <p>
          Fotografías resultantes del proceso de experimentación previo y
          durante la expedición a la Región de Magallanes
        </p>

        <hr />

        {/* <StaticImage
          src="../../images/fotografias/NEG1.jpg"
          alt="imagen"
          placeholder="blurred"
        /> */}

        <StaticImage
          src="../../images/fotografias/NEG2.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG3.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG4.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG5.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG6.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG7.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG8.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG9.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG10.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG11.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG12.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG13.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG14.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG15.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG16.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG17.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG18.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG19.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG20.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG21.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG22.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG23.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG24.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG25.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG26.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG27.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG28.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG29.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG30.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG31.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG32.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG33.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG34.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG36.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG37.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG38.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG39.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG40.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG41.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG42.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG43.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG44.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG45.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG46.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG47.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG48.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG49.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG51.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG52.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG53.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG54.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG55.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/NEG57.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS1.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS2.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS3.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS4.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS5.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS6.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS7.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS8.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS9.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS10.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS11.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS12.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS13.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS14.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS15.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS16.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS17.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS18.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS19.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS20.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS21.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS22.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS23.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS24.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS25.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS26.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS27.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS28.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS29.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS30.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS31.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS32.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS33.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS34.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS36.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS37.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS38.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS39.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS40.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS41.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS42.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS43.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS44.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS45.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS46.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS47.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS48.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS49.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS51.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS52.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS53.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS54.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS55.jpg"
          alt="imagen"
          placeholder="blurred"
        />

        <StaticImage
          src="../../images/fotografias/POS57.jpg"
          alt="imagen"
          placeholder="blurred"
        />
      </Container>
    </Layout>
  );
};

export default Fotografias;
