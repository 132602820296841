import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
`;

export const Children = styled.div`
  padding: 20px;
  max-width: 1440px;
  place-self: center;
  width: calc(100% - 40px);

  @media (max-width: 768px) {
    margin-top: 87px;
  }
`;
